 nav {
    background: rgba(0,0,0,0.3);
    width: max-content;
    display: block;
    padding: 1.2rem 1.4rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
 }

 nav a {
    background: transparent;
    padding: 1.2rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.6rem;
 }

 nav a:hover {
    background: rgba(0,0,0,0.3);
 }

 nav a.active {
    background: var(--color-bg);
    color: var(--color-white);
 }


/* -------------- MEDIA QUERIES (Mid Size devices) -------------- */

@media screen and (max-width: 1024px) {
  
}

/* -------------- MEDIA QUERIES (Small devices) -------------- */

@media screen and (max-width: 600px) {
  
   nav {
      background: rgba(0,0,0,0.3);
      width: max-content;
      display: block;
      padding: 1rem 1rem;
      z-index: 2;
      position: fixed;
      left: 50%;
      transform: translate(-50%);
      bottom: 1rem;
      display: flex;
      gap: 0.8rem;
      border-radius: 3rem;
      backdrop-filter: blur(15px);
   }
  
   nav a {
      background: transparent;
      padding: 1rem;
      border-radius: 50%;
      display: flex;
      color: var(--color-light);
      font-size: 1.4rem;
   }
  
   nav a:hover {
      background: rgba(0,0,0,0.3);
   }
  
   nav a.active {
      background: var(--color-bg);
      color: var(--color-white);
   }
}