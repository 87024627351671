header {
    height: 100vh;
    padding-top: 10rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* --------CTA----------- */

.cta {
    margin-top: 3rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ---------Socials-------- */

.header__socials {
    font-size: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ---------Main Display Image ME-------- */

.me {
    
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* --------- scroll down -------- */

.scroll__down {
    position: absolute;
    right: -5rem;
    bottom: 7rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 2rem;
}

/* -------------- MEDIA QUERIES (Mid Size devices) -------------- */

@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }    
}

/* -------------- MEDIA QUERIES (Small devices) -------------- */

@media screen and (max-width: 600px) {
    header {
        margin-top: -5rem;
        height: 100vh; 
    }

    .cta {
        margin-top: 16rem;
    }

    .cta .btn {
        width: 8rem;
        height: 5rem;
        font-size: 0.9rem;
    }

    .me {
        margin-top: -7rem;
        padding: 1rem 1.5rem 1.5rem 1.5rem;
    }

    .header__container h1 {
        font-size: 3.5rem;
    }
    
    .header__container h4 {
        font-size: 1.6rem;
    }

    .header__container h5 {
        font-size: 1.4rem;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}