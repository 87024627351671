.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform : rotate(0);

}

.about__cards {
    display: grid;
    grid-template-columns: repeat(2);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-primary);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2 rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem; 
}

.about__card h5 {
    font-size: 1rem;
}

.about__card small {
    font-size: 0.8rem;
}

.about__content p {
    margin: 2rem 0 2.6rem;
    
}

p {
    margin: 20px 0;
    color: #555;
}


/* -------------- MEDIA QUERIES (Mid Size devices) -------------- */

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }  

    .about__me {
        width: 40%;
        margin: 0rem auto 0rem;
    }

    .about__content p {
        margin: 1 rem 0 1rem;
    }

    .about__cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
}

/* -------------- MEDIA QUERIES (Small devices) -------------- */

@media screen and (max-width: 600px) {
   .about__me {
    width: 40%;
    /* margin: -10rem auto 0 rem; */
    margin-top: -2rem;
   }

   .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    }

    .about__card {
        padding: 0rem;
    }

    .about__icon {
        font-size: 1rem;
    }

    .about__card h5 {
        font-size: 0.7rem;
    }

    .about__card small {
        font-size: 0.5rem;
        margin: 0.2rem;
        line-height: 1.2;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 0.6rem 0;
        font-size: 0.6rem;
    }
    
    .cta a {
        margin-top: -14rem;
        width: 5rem;
        height: 3rem;
        font-size: 0.8rem;
    }
}