@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
/* --color-primary-variant: rgba(77, 181, 255, 0.4); */
:root {
     --color-bg: #173753;
     --color-bg-variant: #1b4353;
     --color-primary: #517fd5;
     --color-primary-variant: #4d74be;
     --color-white: white;
     --color-light: rgba(255, 255, 255, 0.6); 

     --transition: all 400ms ease;  

     --container-width-lg: 75%;
     --container-width-md: 86%;
     --container-width-sm: 90%;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body{
    font-family: 'Poppins' , sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/palegold.jpeg);
}

/* Styles General */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 650;
}

h1 {
    font-size: 4.5rem;
}

h4 , h5 {
    font-size: 1.8rem;
}

section {
    margin-top: 8rem;
    height: 100vh;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: 8rem;
    padding: 1rem;
    text-align: center;
    justify-items: auto;
    display: inline-block;
    color: var(--color-primary);
    padding: 1.2rem 1.6 rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* -------------- MEDIA QUERIES (Mid Size devices) -------------- */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md); 
    }

    section {
        margin-top: 6 rem;
    }
}

/* -------------- MEDIA QUERIES (Small devices) -------------- */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-sm); 
    }

    section > h2 {
        margin-bottom: 2 rem;
    } 
}   