#contact {
    height: auto;
}


.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option h4 {
    font-size: 2rem;
}

.contact__option h5 {
    font-size: 1.3rem;
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
} 

.contact__option-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1rem;
}


/* -------------- MEDIA QUERIES (Mid Size devices) -------------- */

@media screen and (max-width: 1024px) {
    #contact {
        height: 65vh;
    }

   .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
   }
}

/* -------------- MEDIA QUERIES (Small devices) -------------- */

@media screen and (max-width: 600px) {
    #contact {
        height: auto;
    }

    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
        justify-content: center;
       }
}